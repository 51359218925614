<template>
    <div class="order-detail-container">
        <div class="order-detail-container-top">
            <van-nav-bar title="订单详情" fixed/>
        </div>
        <div class="order-detail-container-content">
            <van-cell-group>
                <van-cell title="订单编号" :value="order.master.billCode"/>
                <van-cell title="订单状态" :value="order.master.billStatusName"/>
                <van-cell title="下单时间" :value="order.master.createTime"/>
                <van-cell title="下单人" :value="order.master.creator"/>
                <van-cell title="试吃客户" :value="order.master.customer"/>
                <van-cell title="配送地址" :value="order.address.city + order.address.county +order.address.address"/>
                <van-cell title="配送方式" :value="order.master.sendType"/>
                <van-cell title="配送站" :value="order.master.site"/>
                <van-cell title="配送员" :value="order.master.poster||'未分配'" v-if="order.master.sendTypeId==6"/>
                <van-cell title="配送电话" :value="order.master.posterMobile" v-if="order.master.posterMobile"/>
                <van-cell title="收货人" :value="order.address.consignee"/>
                <van-cell title="联系电话" :value="order.address.mobile"/>
                <van-cell title="试吃商品" v-if="order.goods.length > 0" is-link :arrow-direction="showGoods?'down':''"
                          @click="showGoods=!showGoods"/>
                <div class="apply-goods" v-show="showGoods">
                    <van-row class="apply-goods-title">
                        <van-col span="16">品名</van-col>
                        <van-col span="4">规格</van-col>
                        <van-col span="4">数量</van-col>
                    </van-row>
                    <van-row class="apply-goods-item" v-for="(goods, index) in order.goods" v-bind:key="index">
                        <van-col span="16">{{goods.name}}</van-col>
                        <van-col span="4">{{goods.spec}}</van-col>
                        <van-col span="4">{{goods.num}}</van-col>
                    </van-row>
                </div>
                <van-cell title="试吃人数" :value="order.master.person"/>
                <van-cell title="配送日期" :value="order.master.sendDate"/>
                <van-cell title="配送时间" :value="order.master.sendTime"/>
                <van-cell title="内部留言" :value="order.master.remark"/>
                <div class="order-detail-container-btn">
                    <!-- 每个元素的两侧间隔相等 -->
                    <van-row type="flex" justify="space-around">
                        <van-col span="8" v-show="order.master.billStatus==0 && auth.indexOf('/sale/order/audit') >= 0">
                            <van-button type="info" size="small" @click="onAudit" icon="success">审批订单</van-button>
                        </van-col>
                        <van-col span="8" v-show="order.master.billStatus==0 && auth.indexOf('/sale/order/audit') < 0">
                            <van-button type="warning" size="small" @click="onSendAudit"
                                        icon="my iconfont icon-message">提醒审批
                            </van-button>
                        </van-col>
                        <van-col span="8" v-show="order.master.billStatus==0">
                            <van-button type="primary" size="small" @click="onEdit" icon="edit">修改订单</van-button>
                        </van-col>
                        <van-col span="8" v-show="order.master.billStatus==0||order.master.billStatus==1">
                            <van-button type="danger" size="small" @click="onCancel" icon="cross">取消订单</van-button>
                        </van-col>
                    </van-row>
                </div>
            </van-cell-group>
        </div>
        <!-- 弹出区域 -->
        <div class="order-search-container-popup">
            <!-- 领导 -->
            <van-action-sheet
                    v-model="picker.leader"
                    :actions="leaderList"
                    cancel-text="取消"
                    @cancel="picker.leader=false"
                    @select="doSendAudit"
                    title="请选择提醒对象"
            />
            <!-- 修改订单 -->
            <van-popup v-model="picker.edit" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <edit :param.sync="order.master" :key="order.master.billNo" @onClose="onChange"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import edit from './input'

    export default {
        name: 'order-detail',
        components: {
            edit
        },
        props: ['node', 'param'],
        data() {
            return {
                auth: this.$store.state.user.auth || [],
                order: {
                    master: {
                        billCode: '',
                        billNo: 0,
                        billStatus: '',
                        billStatusName: '',
                        createTime: '',
                        creator: '',
                        customer: '',
                        sendType: '',
                        city: '',
                        county: '',
                        address: '',
                        consignee: '',
                        mobile: '',
                        person: '',
                        sendDate: '',
                        sendTime: '',
                        remark: '',
                    },
                    goods: [],
                    address: [],
                    customer: []
                },
                showGoods: true,
                leaderList: [],
                picker: {
                    leader: false,
                    edit: false,
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            // 初始化
            init() {
                let that = this;
                if (that.param && that.param.billNo > 0) {
                    that.leaderList = [];
                    that.$api.foretaste.detail(that.param.billNo).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.order = res.data;
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }
            },
            // 修改订单
            onEdit() {
                return this.$toast('请取消后重新下单');
                //this.picker.edit = true;
            },
            // 修改成功处理
            onChange() {
                this.picker.edit = false;
                this.init();
            },
            // 审批订单
            onAudit() {
                let that = this;
                that.$dialog.confirm({
                    message: '确认审批吗？'
                }).then(() => {
                    that.$api.foretaste.audit(that.order).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.$toast.success(res.msg);
                            that.order.master.billStatus = 1;
                            that.order.master.billStatusName = '已确认';
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }).catch(() => {
                    // on cancel
                });
            },
            // 提醒审批
            onSendAudit() {
                let that = this;
                if (that.leaderList.length == 0) {
                    that.$api.user.loadLeader(that.order.master.userId).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.leaderList = res.data;
                            that.picker.leader = true;
                        } else {
                            that.$dialog.alert({
                                message: res.msg
                            });
                        }
                    });
                } else {
                    that.picker.leader = true;
                }
            },
            doSendAudit(item) {
                let that = this;
                that.$api.foretaste.sendAudit({billNo: that.order.master.billNo, userId: item.id}).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.$toast.success(res.msg);
                        that.picker.leader = false;
                    } else {
                        that.$dialog.alert({
                            message: res.msg
                        });
                    }
                });
            },
            // 取消订单
            onCancel() {
                let that = this;
                if (this.order.master.billStatus == 0 || this.order.master.billStatus == 1) {
                    that.$dialog.confirm({
                        message: '操作不可逆，确认取消吗？'
                    }).then(() => {
                        that.$api.foretaste.cancel({billNo: this.order.master.billNo}).then(response => {
                            let res = response.data;
                            if (res.code == 1) {
                                that.$toast.success(res.msg);
                                that.order.master.billStatus = 10;
                                that.order.master.billStatusName = '已取消';
                            } else {
                                that.$toast.fail(res.msg);
                            }
                        });
                    }).catch(() => {
                        // on cancel
                    });

                }
            }
        },
        watch: {
            'param.billNo': function (newVal, oldVal) {
                if (newVal != oldVal && oldVal > 0) {
                    this.init();
                }
            }
        }
    }
</script>

<style scoped>
    .order-detail-container {
        background-color: #f5f5f5;
        height: 100%;
        width: 100%;
    }

    .order-detail-container-content {
        position: fixed;
        top: 46px;
        bottom: 0;
        overflow-y: scroll;
        left: 0;
        right: 0;
    }

    .order-detail-container-btn {
        text-align: center;
        background-color: #fff;
        padding: 10px 16px;
    }

    .van-cell__value {
        flex: 3
    }

    .apply-goods {
        text-align: center;
        border-bottom: 1px solid #ebedf0;
        color: #323232;
        line-height: 30px;
        font-size: 14px;
    }

    .apply-goods-title {
        background: #f5f5f5;
    }

    .apply-goods-item {
        border-bottom: 1px dashed #ebedf0;
    }

    .apply-goods-item:last-child {
        border: none;
    }

</style>
