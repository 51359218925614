<template>
    <div class="goods-selector">
        <div class="goods-selector-top">
            <van-nav-bar title="商品列表" fixed/>
        </div>
        <div class="goods-selector-search">
            <van-search placeholder="搜索一下" v-model="option.wd" show-action @search="onLoad">
                <div slot="action" @click="onSearch">搜索</div>
            </van-search>
        </div>
        <div class="goods-selector-list">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="finishedText"
                    @load="onLoad"
            >
                <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
                    <template slot="left">
                        <van-button square type="info" icon="success" text="选择" @click="onSelect(item)"/>
                    </template>
                    <van-cell :border="true" :value="item.spec">
                        <template slot="title">
                            <span class="custom-title">{{item.name}}</span>
                        </template>
                    </van-cell>
                </van-swipe-cell>
            </van-list>
        </div>
        <div class="goods-selector-pagination" v-show="pages>0">
            <van-pagination
                    v-model="option.page"
                    :page-count="pages"
                    mode="simple"
                    @change="onLoad"
            />
        </div>
        <div class="goods-selector-popup">
            <van-popup v-model="picker.spec" position="bottom" :style="{width:'100%',height:'50%'}">
                <van-picker :columns="specList" show-toolbar
                            @cancel="picker.spec=false"
                            @confirm="onConfirm"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    export default {
        name: "goods-selector",
        props: ['cityId'],
        data() {
            return {
                list: [],
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                option: {
                    cityId: this.cityId,
                    page: 1, // 页码
                    size: 15, // 每页显示数
                    bid: 0, // 大分类id
                    fid: 0, // 口味id
                    sid: 0, // 场景id
                    tid: 0, // 标签id
                    wd: '' // 关键词
                },
                picker: {
                    spec: false
                },
                specList: []
            }
        },
        mounted: function () {
            this.init()
        },
        methods: {
            init() {
                this.onLoad()
            },
            onSearch() {
                this.option.page = 1
                this.onLoad()
            },
            onLoad() {
                let that = this
                that.list = []
                that.loading = true
                that.finished = false
                that.$api.goods.search(that.option).then(response => {
                    that.loading = false
                    that.finished = true
                    let res = response.data
                    if (res.code == 1) {
                        if (res.data.count == 0) {
                            that.finishedText = '暂无数据'
                        } else {
                            that.finishedText = ''
                        }
                        that.pages = Math.ceil(res.data.count / that.option.size)
                        that.list = res.data.list
                    } else {
                        that.$toast(res.msg)
                    }
                })
            },
            onSelect(item) {
                let that = this
                that.specList = []
                if (item.list.length > 1) {
                    item.list.forEach(function (v) {
                        that.specList.push({id: v.id, text: v.spec})
                    })
                    that.picker.spec = true
                } else {
                    that.onChange(item.id)
                }
            },
            onConfirm(val) {
                this.picker.spec = false
                this.onChange(val.id)
            },
            onChange(id) {
                let that = this
                that.$api.goods.detail({cityId: that.cityId, id: id}).then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        res.data.num = 1
                        this.$emit('onChange', res.data)
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            }
        }
    }
</script>

<style scoped>

    .goods-selector {
        height: 100%;
        width: 100%;
    }

    .van-cell__title {
        flex: 2
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }

    .goods-selector-top {
        position: fixed;
        width: 100%;
        height: 46px;
        top: 0;
    }

    .goods-selector-search {
        position: fixed;
        top: 46px;
        width: 100%;
    }

    .goods-selector-list {
        position: fixed;
        width: 100%;
        top: 100px;
        bottom: 40px;
        overflow-y: scroll;
    }

    .goods-selector-pagination {
        position: fixed;
        width: 100%;
        height: 40px;
        bottom: 0;
    }
</style>
