<template>
    <div class="order-input-container">
        <div class="order-input-container-top">
            <van-nav-bar title="订单录入" fixed/>
        </div>
        <div class="order-input-container-form">
            <van-cell-group>
                <van-cell title="试吃客户" icon="my iconfont icon-company" is-link :value="order.customer.title||'未选'"
                          @click="picker.customer=true"/>
                <van-panel>
                    <div slot="header">
                        <van-cell title="收货地址" icon="my iconfont icon-dizhi" is-link
                                  :value="order.address.id ? '' : '未选'" @click="popupAddress"/>
                    </div>
                    <div slot="default" v-if="order.address.id">
                        <div class="order-address" @click="picker.address=true">
                            <div class="order-address-consignee">
                                {{order.address.consignee}} {{order.address.mobile}}
                            </div>
                            <div class="order-address-detail">
                                <van-tag plain type="warning" v-if="order.address.isDefault==1">默认</van-tag>
                                <van-tag plain type="primary" v-if="order.address.type==1">直送</van-tag>
                                <van-tag plain type="success" v-if="order.address.type==2">自提</van-tag>
                                {{order.address.city}}{{order.address.county}}{{order.address.address}}
                            </div>
                        </div>
                    </div>
                </van-panel>
                <van-panel>
                    <div slot="header">
                        <van-cell title="试吃商品" icon="my iconfont icon-shangpin" is-link
                                  :value="order.goods.length ? '已选' : '未选'" @click="popupGoods"/>
                    </div>
                    <div slot="default" v-if="order.goods.length>0">
                        <div class="goods-list">
                            <van-cell-group>
                                <van-swipe-cell v-for="(goods, index) in order.goods" v-bind:key="index">
                                    <template slot="default">
                                        <div :class="{'goods-item':true,'border':index>=0}">
                                            <div class="goods-name">{{goods.name}}</div>
                                            <div class="goods-spec">{{goods.spec}} x {{goods.num}}</div>
                                        </div>
                                    </template>
                                    <template slot="right">
                                        <van-button square type="danger" text="删除" @click="onRemoveGoods(index)"/>
                                    </template>
                                </van-swipe-cell>
                            </van-cell-group>
                        </div>
                    </div>
                </van-panel>
                <van-cell title="试吃人数" icon="my iconfont icon-renshu" is-link :value="order.master.person||'未选'"
                          @click="popupKeyboard"/>
                <van-cell title="配送日期" icon="my iconfont icon-riqi" is-link :value="order.master.sendDate||'未选'"
                          @click="popupSendDate"/>
                <van-cell title="配送时间" icon="my iconfont icon-shijian" is-link :value="order.master.sendTime||'未选'"
                          @click="popupSendTime"/>
                <van-field
                        v-model="order.master.remark"
                        type="textarea"
                        clearable
                        label="内部留言"
                        placeholder="请输入内部留言"
                        left-icon="my iconfont icon-beizhu"
                        maxlength="100"
                        show-word-limit
                        autosize
                />
            </van-cell-group>
        </div>
        <div class="order-input-container-btn">
            <van-button type="info" icon="passed" size="large" @click="onSubmit"
                        text="提交"/>
        </div>
        <div class="order-input-container-popup">
            <!-- 客户列表 -->
            <van-popup v-model="picker.customer" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left" :style="{width:'100%',height:'100%'}">
                <customerSelector @onChange="onChangeCustomer"/>
            </van-popup>
            <!-- 数字键盘 -->
            <van-number-keyboard
                    v-model="order.master.person"
                    :show="picker.keyboard"
                    theme="custom"
                    extra-key=""
                    close-button-text="完成"
                    maxlength="3"
                    @blur="picker.keyboard = false"
            />
            <!-- 地址列表 -->
            <van-popup v-model="picker.address" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left" :style="{width:'100%',height:'100%'}">
                <addressSelector @onChange="onChangeAddress" :customer.sync="order.customer"
                                 :address.sync="order.address" v-if="order.customer.uid > 0"/>
            </van-popup>
            <!-- 商品列表 -->
            <van-popup v-model="picker.goods" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left" :style="{width:'100%',height:'100%'}">
                <goodsSelector @onChange="onChangeGoods" :cityId.sync="order.address.cityId" v-if="order.address.cityId > 0"/>
            </van-popup>
            <!-- 配送日期 -->
            <van-popup v-model="picker.sendDate" position="bottom" :style="{width:'100%',height:'50%'}">
                <div class="send-time-popup">
                    <div class="send-time-popup-title">
                        <van-nav-bar title="配送日期"/>
                    </div>
                    <div class="send-time-list">
                        <van-row v-if="sendDateList.length>0">
                            <van-col span="24" v-for="(item, index) in sendDateList" v-bind:key="index"
                                     @click="setSendDate(item)">
                                <div :class="{'send-time-item':true,'active':item.date==order.master.sendDate}">
                                    {{item.date}} {{item.week}}
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </van-popup>
            <!-- 配送时间 -->
            <van-popup v-model="picker.sendTime" position="bottom" :style="{width:'100%',height:'50%'}">
                <div class="send-time-popup">
                    <div class="send-time-popup-title">
                        <van-nav-bar title="配送时间"/>
                    </div>
                    <div class="send-time-list" v-if="sendTimeList.length>0">
                        <van-row v-if="sendTimeMode == 0">
                            <van-col span="6" v-for="(item, index) in sendTimeList" v-bind:key="index"
                                     @click="setSendTime(item)">
                                <div :class="{'send-time-item':true,'active':item.time==order.master.sendTime,'disabled':item.hot}">
                                    {{item.time}} <span v-if="item.hot">满</span>
                                </div>
                            </van-col>
                        </van-row>
                        <van-row v-if="sendTimeMode == 1">
                            <van-col span="12" v-for="(item, index) in sendTimeList" v-bind:key="index"
                                     @click="setSendTime(item)">
                                <div :class="{'send-time-item':true,'active':item.time==order.master.sendTime,'disabled':item.hot}">
                                    {{item.time}} <span v-if="item.hot">满</span>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </van-popup>
            <!-- 订单详情 -->
            <van-popup v-model="picker.order" position="bottom" closeable close-icon="my iconfont icon-guanbi"
                       :style="{width:'100%',height:'100%'}" get-container="#app">
                <orderDetail :param.sync="order.master" :key="order.master.billNo" @onClose="picker.order=false"
                             v-if="order.master.billNo > 0"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import customerSelector from '../customer/selector'
    import addressSelector from '../../shop/member/address/selector'
    import goodsSelector from '../../shop/goods/selector'
    import orderDetail from './detail'

    export default {
        name: 'order-input',
        components: {
            customerSelector,
            addressSelector,
            goodsSelector,
            orderDetail,
        },
        props: ['node', 'customer', 'param'],
        data() {
            return {
                order: {
                    master: {
                        billNo: 0,
                        person: '',
                        remark: '',
                        sendDate: '',
                        sendTime: '',
                    },
                    goods: [],
                    address: {
                        id: 0,
                        city: '',
                        cityId: 0,
                        county: '',
                        address: '',
                    },
                    customer: {
                        uid: 0
                    },
                },
                billNo: 0,
                sendDateList: [],
                sendTimeMode: [],
                sendTimeList: [],
                picker: {
                    customer: false,
                    address: false,
                    goods: false,
                    keyboard: false,
                    sendDate: false,
                    sendTime: false,
                    order: false,
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            // 初始化
            init() {
                let that = this;
                if (that.customer && that.customer.id > 0) {
                    that.$api.company.detail(that.customer.id).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.order.customer = res.data;
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    })
                }
                if (that.param && that.param.billNo > 0) {
                    that.$api.foretaste.detail(that.param.billNo).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.order = res.data;
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }
            },
            // 修改试吃客户
            onChangeCustomer(customer) {
                // 清空已选地址
                if (customer !== this.order.customer) {
                    this.order.address = {
                        id: 0
                    };
                    this.order.goods = [];
                }
                this.picker.customer = false;
                this.order.customer = customer;
            },
            // 弹出地址列表
            popupAddress() {
                if (!this.order.customer.id)
                    return this.$toast('请先选择试吃客户');
                this.picker.address = true;
            },
            // 弹出商品列表
            popupGoods() {
                if (!this.order.address.id)
                    return this.$toast('请先选择收货地址');
                this.picker.goods = true;
            },
            // 弹出数字键盘
            popupKeyboard() {
                this.picker.keyboard = true;
            },
            // 弹出配送日期
            popupSendDate() {
                let that = this;
                if (that.order.goods.length == 0)
                    return that.$toast('请先选择试吃商品');
                if (that.sendDateList.length == 0) {
                    that.$api.foretaste.loadSendDate(that.order).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.sendDateList = res.data;
                            that.picker.sendDate = true;
                        } else {
                            that.$toast(res.msg);
                        }
                    });
                } else {
                    that.picker.sendDate = true;
                }
            },
            setSendDate(item) {
                this.order.master.sendDate = item.date;
                this.picker.sendDate = false;
            },
            // 弹出配送时间
            popupSendTime() {
                let that = this;
                if (!that.order.master.sendDate)
                    return that.$toast('请先选择配送日期');
                if (that.sendTimeList.length == 0) {
                    that.$api.foretaste.loadSendTime(that.order).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.formatSendTime(res.data);
                        } else {
                            that.$toast(res.msg);
                        }
                    });
                } else {
                    that.picker.sendTime = true;
                }
            },
            // 格式化配送时间
            formatSendTime(data) {

                this.sendTimeMode = data.mode;

                let list = [];

                switch (this.sendTimeMode) {

                    case 0: // 时间点模式

                        data.list.forEach(function (item) {
                            item.list.forEach(function (v) {
                                list.push({
                                    time: item.hour + ':' + (v.min == 0 ? '00' : v.min),
                                    hot: v.hot,
                                    noon: item.noon == 'am' ? '上午' : '下午'
                                });
                            });
                        });

                        break;

                    case 1: // 时间段模式

                        data.list.forEach(function (v) {
                            list.push({
                                time: v.start + '-' + v.end,
                                hot: v.hot,
                                noon: '时段'
                            });
                        });

                        break;

                    default:
                }

                this.sendTimeList = list;

                this.picker.sendTime = true;
            },
            // 设置配送时间
            setSendTime(item) {
                if (item.hot)
                    return false;
                this.order.master.sendTime = item.time;
                this.picker.sendTime = false;
            },
            // 修改地址
            onChangeAddress(address) {
                // 清空已选商品
                if (address !== this.order.address) {
                    this.order.goods = []
                }
                this.order.address = address;
                this.picker.address = false
            },
            // 修改商品
            onChangeGoods(goods) {
                this.order.goods.push(goods);
                this.picker.goods = false;
                this.clearSendDateTime();
            },
            clearSendDateTime() {
                this.sendDateList = [];
                this.sendTimeList = [];
                this.order.master.sendDate = '';
                this.order.master.sendTime = '';
            },
            onRemoveGoods(index) {
                this.order.goods.splice(index, 1)
            },
            // 修改配送日期
            onChangeSendDate(value) {
                let date = value;
                this.order.master.sendDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                this.picker.sendDate = false
                this.sendTimeList = [];
                this.order.master.sendTime = '';
            },
            // 提交订单
            onSubmit() {
                let that = this;
                that.$api.foretaste.input(this.order).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.$dialog.confirm({
                            title: '订单提交成功',
                            confirmButtonText: '详情',
                            cancelButtonText: '关闭',
                            message: '订单号：' + res.data.billCode
                        }).then(() => {
                            // on cancel
                            that.order.master.billNo = res.data.billNo;
                            that.picker.order = true;
                        }).catch(() => {
                            // on cancel
                        });
                        if (that.node) {
                            that.$emit('onClose', that.node)
                        } else {
                            that.$emit('onClose')
                        }
                    } else {
                        that.$toast.fail(res.msg);
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .order-input-container {
        background-color: #f5f5f5;
        height: 100%;
        width: 100%;
    }

    .order-input-container-form {
        position: fixed;
        top: 46px;
        bottom: 50px;
        overflow-y: scroll;
        left: 0;
        right: 0;
    }

    .order-input-container-btn {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;
    }

    .van-cell__value {
        flex: 3
    }

    .order-address {
        padding: 0 20px 10px;
    }

    .order-address-consignee {
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
    }

    .order-address-detail {
        color: #7d7e80;
        font-size: 12px;
        line-height: 16px;
    }

    .order-address-detail .van-tag {
        margin-right: 5px;
    }

    .order-address-detail .van-tag:last-child {
        margin-right: 0;
    }

    .goods-list {

    }

    .goods-list .goods-item {
        display: flex;
        padding: 10px 16px;
        color: #323233;
        font-size: 14px;
        line-height: 24px;

    }

    .goods-list .goods-item.border {
        border-bottom: 1px solid #ebedf0;
    }

    .goods-list .goods-item .goods-name {
        display: flex;
        flex: 2;
        position: relative;
        overflow: hidden;
        text-align: left;
        vertical-align: middle;
    }

    .goods-list .goods-item .goods-spec {
        position: relative;
        overflow: hidden;
        color: #969799;
        float: right;
    }

    .send-time-popup {
        position: relative;
    }

    .send-time-popup-title {
        position: relative;
        width: 100%;
        height: 46px;
        background: #fff;
    }

    .send-time-popup-title .van-nav-bar {
        position: fixed;
        width: 100%;
    }

    .send-time-list {
        padding: 4px;
    }

    .send-time-list .send-time-item {
        text-align: center;
        border: 1px solid #eee;
        margin: 4px;
        border-radius: 5px;
    }

    .send-time-list .send-time-item.active {
        border: 1px solid #1989fa;
        background-color: #1989fa40;
    }

    .send-time-list .send-time-item.disabled {
        background-color: #f5f5f5;
        color: #e0e0e0;
    }

</style>
